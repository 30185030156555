import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./MetricsCard.css";

export function MetricsCard(props: {
  icon: IconDefinition;
  title: string | JSX.Element;
  value: string;
  color: string;
}) {
  return (
    <div className="Metric-card">
      <FontAwesomeIcon color="#6cd1ef" icon={props.icon} size="2x" />
      <p>{props.title}</p>
      <span className={props.color}>{props.value}</span>
    </div>
  );
}

export default MetricsCard;