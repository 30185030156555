import React, { useEffect, useState } from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  onCompletion: () => void;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ onCompletion }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress < 80 ? prevProgress + 5 : prevProgress + 3;

        if (newProgress >= 100) {
          onCompletion();
          return 0;
        }

        return newProgress;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [onCompletion]);

  return (
    <div className="Progress-container">
      <div className="Progress-bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
}

export default ProgressBar;
