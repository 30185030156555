export interface MetricsData {
  temperature?: number;
  humidity?: number;
  co2?: number;
  pressure?: number;
  lightmeter?: number;
  altitude?: number;
  timestamp?: Date;
}

export async function fetchMetrics() {
  const response = await fetch("https://metrics.ars9.space");
  const data = await response.json();
  return {
    temperature: Number(data.temperature),
    humidity: Number(data.humidity),
    co2: Number(data.co2Equivalent),
    pressure: Number(data.pressure),
    timestamp: new Date(data.timestamp * 1000),
  };
}

export class MetricsService {
  private readonly url: string = "https://metrics.ars9.space";

  public async fetch(site: string): Promise<MetricsData> {
    const response = await fetch(`${this.url}/data/${site}`);
    const data = await response.json();
    return {
      temperature: Number(data.temperature) ?? undefined,
      humidity: Number(data.humidity) ?? undefined,
      co2: Number(data.co2Equivalent) ?? undefined,
      pressure: Number(data.pressure) ?? undefined,
      altitude: Number(data.altitude) ?? undefined,
      lightmeter: Number(data.lightmeter) ?? undefined,
      timestamp: new Date(data.timestamp * 1000),
    };
  }
}
