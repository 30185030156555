import { useEffect, useState } from "react";

import "./EthColorCoder.css";

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

function ethAddrColorCode(address: string) {
  address.startsWith("0x") && (address = address.slice(2));

  let i = 0;

  const colors = [
    address.slice(i, (i += 2)).repeat(3),
    address.slice(i, (i += 6)),
    address.slice(i, (i += 6)),
    address.slice(i, (i += 6)),
    address.slice(i, (i += 6)),
    address.slice(i, (i += 6)),
    address.slice(i, (i += 6)),
    address.slice(i, (i += 2)).repeat(3),
  ];

  return colors;
}

function randomAddress(): string {
  const hex = "0123456789abcdef";
  let address = "0x";

  for (let i = 0; i < 40; i++) {
    address += hex[Math.floor(Math.random() * hex.length)];
  }

  return address;
}

function validateAddress(address?: string): boolean {
  return address !== null && address!.match(/^(0x)?[a-fA-F0-9]{40}$/) !== null;
}

export function EthColorCoder() {
  const [addr, setAddr] = useState<string>(ZERO_ADDRESS);

  useEffect(() => {
    setAddr(randomAddress());
  }, []);

  return (
    <section className="ColorCodes Block">
      <h2>Ethereum Address Color Coding</h2>
      <input
        type="text"
        placeholder="Enter an Ethereum address"
        value={addr}
        className="ColorCodes-input"
        onChange={(event) => setAddr(event.target.value)}
      ></input>

      <EthColorBadge address={addr} />

      <button
        className="ColorCodes-button"
        onClick={() => {
          setAddr(randomAddress());
        }}
      >
        Randomize
      </button>
    </section>
  );
}

function EthColorBadge(props: { address?: string }) {
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const address = validateAddress(props.address)
      ? props.address!
      : ZERO_ADDRESS;
    setColors(ethAddrColorCode(address));
  }, [props]);

  return (
    <div className="ColorCodes-colors">
      {colors.map((color, index) => (
        <div key={index + color} style={{ backgroundColor: `#${color}` }}></div>
      ))}
    </div>
  );
}

export default EthColorCoder;
