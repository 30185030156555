import { Metrics } from "./components/Metrics";
import { EthColorCoder } from "./components/EthColorCoder";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Block-Transparent">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <Metrics title="Site 1" site="site1"></Metrics>
        <Metrics title="Site 2" site="site2"></Metrics>
        <Metrics title="Site 3" site="site3"></Metrics>
        <EthColorCoder></EthColorCoder>
      </header>
    </div>
  );
}

export default App;
