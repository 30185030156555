import { useEffect, useState } from "react";
import {
  faThermometerHalf,
  faTint,
  faWind,
  faTree,
  faLightbulb,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

import "./Metrics.css";
import { MetricsCard } from "./MetricsCard";
import { ProgressBar } from "./ProgressBar";
import { MetricsData, MetricsService } from "./MetricsService";

enum MetricsState {
  Loading,
  Updated,
  Error,
}

export function Metrics(props: { title: string; site: string }) {
  const [state, setState] = useState<MetricsState>(MetricsState.Loading);
  const [metrics, setMetrics] = useState<MetricsData>({});

  const service = new MetricsService();

  function fetchMetrics() {
    service.fetch(props.site).then(
      (data) => {
        setMetrics(data);
        setState(MetricsState.Updated);
      },
      (error) => {
        console.error(`Data fetch error: ${error}`);
        setState(MetricsState.Error);
      }
    );
  }

  useEffect(() => {
    fetchMetrics();
  }, []);

  function formatDateTime(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <section className="Metrics Block">
      <h2 className="Metrics-title">{props.title}</h2>
      <ProgressBar onCompletion={fetchMetrics} />
      {state === MetricsState.Loading && <div>Loading...</div>}
      {state === MetricsState.Updated && (
        <div className="Metrics-grid">
          {!Number.isNaN(metrics.temperature) && (
            <MetricsCard
              icon={faThermometerHalf}
              title="Temperature"
              value={`${metrics.temperature!.toFixed(1)} °C`}
              color={temperatureColor(metrics.temperature)}
            />
          )}
          {!Number.isNaN(metrics.humidity) && (
            <MetricsCard
              icon={faTint}
              title="Humidity"
              value={`${metrics.humidity}%`}
              color={humidityColor(metrics.humidity)}
            />
          )}
          {!Number.isNaN(metrics.pressure) && (
            <MetricsCard
              icon={faWind}
              title="Pressure"
              value={`${(metrics.pressure! / 1000).toFixed(2)} kPa`}
              color={pressureColor(metrics.pressure)}
            />
          )}
          {!Number.isNaN(metrics.lightmeter) && (
            <MetricsCard
              icon={faLightbulb}
              title="Light"
              value={`${metrics.lightmeter!.toFixed()} lux`}
              color=""
            />
          )}
          {!Number.isNaN(metrics.altitude) && (
            <MetricsCard
              icon={faArrowUp}
              title="Altitude"
              value={`${metrics.altitude!.toFixed(0)} m`}
              color=""
            />
          )}
          {!Number.isNaN(metrics.co2) && (
            <MetricsCard
              icon={faTree}
              title={
                <>
                  CO<sub>2</sub>
                </>
              }
              value={`${metrics.co2} PPM`}
              color={co2Color(metrics.co2)}
            />
          )}
        </div>
      )}
      {metrics.timestamp && (
        <div className="Metrics-timestamp">
          Data timestamp: {formatDateTime(metrics.timestamp)}
        </div>
      )}
      {state === MetricsState.Error && (
        <div className="Metrics-error">Unable to fetch metrics</div>
      )}
    </section>
  );
}

function temperatureColor(temperature?: number): string {
  if (temperature === undefined) {
    return "";
  }
  if (temperature < 18) {
    return "Temperature-cold";
  } else if (temperature < 20) {
    return "Temperature-cool";
  } else if (temperature < 24) {
    return "Temperature-normal";
  } else if (temperature < 26) {
    return "Temperature-warm";
  } else {
    return "Temperature-hot";
  }
}

function humidityColor(humidity?: number): string {
  if (humidity === undefined) {
    return "";
  }
  if (humidity < 30) {
    return "Humidity-dry";
  } else if (humidity < 40) {
    return "Humidity-normal";
  } else if (humidity < 50) {
    return "Humidity-humid";
  } else {
    return "Humidity-wet";
  }
}

function pressureColor(pressure?: number): string {
  if (pressure === undefined) {
    return "";
  }
  if (pressure < 94.189) {
    return "Pressure-very-low";
  } else if (pressure < 97.189) {
    return "Pressure-low";
  } else if (pressure < 101.189) {
    return "Pressure-normal";
  } else if (pressure < 104.189) {
    return "Pressure-high";
  } else {
    return "Pressure-very-high";
  }
}

function co2Color(co2?: number): string {
  if (co2 === undefined) {
    return "";
  }
  if (co2 < 400) {
    return "CO2-low";
  } else if (co2 < 800) {
    return "CO2-moderate";
  } else if (co2 < 1000) {
    return "CO2-elevated";
  } else if (co2 < 2500) {
    return "CO2-high";
  } else {
    return "CO2-very-high";
  }
}

export default Metrics;
